import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonAlert, IonButton, IonSpinner, IonText } from "@ionic/react";
import { fetchShiftDetails } from "@src/app/api/shift";
import { calculateShiftMandatoryBreakTimes } from "@src/app/hcpShifts/helper";
import { validateTimeKeeping } from "@src/app/store/ongoingShifts/apiV2";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { doesShiftHaveWorkedBreak } from "@src/appV2/Shifts/Shift/utils";
import { USER_EVENTS, UserEvent } from "@src/constants";
import { Shift, TimeRange } from "@src/lib/interface/src/lib/shift";
import { differenceInMinutes, isAfter, parseISO } from "date-fns";
import { useState } from "react";
import "./style.scss";

import { ShiftActionTime } from "./shiftActionTime";
import { useAdjustTimeForMultiDayShifts } from "./useAdjustTimeForMultiDayShifts";
import {
  getBreakEndTimeError,
  getBreakStartTimeError,
  getClockInTimeError,
  getClockOutTimeError,
  validateShiftTime,
} from "../../../utils/shiftEventTimeValidation";
import { ShiftDetailsAlerts, useDeprecatedAlertsForShiftDetails } from "../shiftDetails/alerts";
import {
  getClockInEditHelperText,
  getClockOutEditHelperText,
  getLunchInEditHelperText,
  getLunchOutEditHelperText,
} from "../utils/shiftTimeEditHelperText";

type ShiftTimeSummaryProps = {
  shift: Shift;
  originalClockInOut: TimeRange;
  originalLunchInOut: TimeRange;
  initialSubmissionClockInOut: TimeRange;
  initialSubmissionLunchInOut: TimeRange;
  onShiftTimeEdit: (isOpen: boolean) => void;
  onShiftTimeSave: (
    submitClockIn?: string,
    submitClockOut?: string,
    submitLunchOut?: string,
    submitLunchIn?: string
  ) => void;
  isEditable: boolean;
  isTimeEditButtonVisible: boolean;
  isNewTimeSheetEnabled: boolean;
  isEarlyClockInEnabled: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  isMandatoryBreakEnabled: boolean;
  isTimeSheetEditDisabled: boolean;
  qrCodeFeedbackBeforeClockOutIsEnabled?: boolean;
};

export const ShiftTimeSummary = ({
  shift,
  originalClockInOut,
  originalLunchInOut,
  initialSubmissionClockInOut,
  initialSubmissionLunchInOut,
  onShiftTimeEdit,
  onShiftTimeSave,
  isEditable,
  isTimeEditButtonVisible,
  isNewTimeSheetEnabled,
  isEarlyClockInEnabled,
  isSolveUnpaidBreaksEnabled,
  isMandatoryBreakEnabled,
  isTimeSheetEditDisabled,
  qrCodeFeedbackBeforeClockOutIsEnabled = false,
}: ShiftTimeSummaryProps) => {
  const [shiftGeofence, setShiftGeofence] = useState(shift.geofence);

  const isRadarTimekeepingValidationsEnabled =
    shift.facility?.featureSettings?.radarTimekeepingValidations ?? false;

  let shiftTime = 0;
  let lunchTime = 0;

  if (isDefined(initialSubmissionClockInOut.start) && isDefined(initialSubmissionClockInOut.end)) {
    shiftTime =
      differenceInMinutes(
        parseISO(initialSubmissionClockInOut.end),
        parseISO(initialSubmissionClockInOut.start)
      ) / 60;
  }
  if (isDefined(initialSubmissionLunchInOut.start) && isDefined(initialSubmissionLunchInOut.end)) {
    lunchTime =
      differenceInMinutes(
        parseISO(initialSubmissionLunchInOut.end),
        parseISO(initialSubmissionLunchInOut.start)
      ) / 60;
  }

  const facilityTimezone = shift?.facility?.tmz ? shift.facility.tmz : "";

  const [showSpinner, setShowSpinner] = useState(false);
  const timeElapsed = (shiftTime as number) - (lunchTime || 0);

  const hours = Math.trunc(timeElapsed);
  const minutes = Math.round((timeElapsed - hours) * 60);

  const [submitClockIn, setSubmitClockIn] = useState(initialSubmissionClockInOut.start);
  const [submitClockOut, setSubmitClockOut] = useState(initialSubmissionClockInOut.end);
  const [submitLunchOut, setSubmitLunchOut] = useState(initialSubmissionLunchInOut.start);
  const [submitLunchIn, setSubmitLunchIn] = useState(initialSubmissionLunchInOut.end);

  const [clockInEditText, setClockInEditText] = useState("");
  const [lunchInEditText, setLunchInEditText] = useState("");
  const [lunchOutEditText, setLunchOutEditText] = useState("");
  const [clockOutEditText, setClockOutEditText] = useState("");

  const [isBreakTimeTouched, setIsBreakTimeTouched] = useState(false);

  const { showErrorToast } = useToast();

  const shiftDetailsAlerts: ShiftDetailsAlerts = useDeprecatedAlertsForShiftDetails();
  const { alert } = shiftDetailsAlerts;

  const { adjustTimeForMultiDayShifts } = useAdjustTimeForMultiDayShifts(shift);

  const hasGeofencedBreak = Boolean(shiftGeofence?.startBreak || shiftGeofence?.endBreak);

  const breakDurationInMinutes =
    isDefined(submitLunchOut) && isDefined(submitLunchIn)
      ? differenceInMinutes(parseISO(submitLunchIn), parseISO(submitLunchOut))
      : 0;

  const hasWorkedBreakTime =
    isSolveUnpaidBreaksEnabled &&
    isMandatoryBreakEnabled &&
    doesShiftHaveWorkedBreak({
      clockInOut: {
        start: submitClockIn,
        end: submitClockOut,
      },
      lunchInOut: {
        start: submitLunchOut,
        end: submitLunchIn,
      },
    });

  const sendEditableErrorToSegment = (
    userEvents: UserEvent,
    errorMessage: string,
    submittedTime?: string
  ) => {
    logEvent(userEvents, {
      error: errorMessage,
      submittedTime,
      shiftId: shift._id,
      shiftStart: shift.start,
      shiftEnd: shift.end,
      isEarlyClockInEnabled,
      actualClockOutTime: shift?.clockInOut?.end,
      geofence: shiftGeofence,
    });
  };

  const validateNewClockIn = async (time: string) => {
    const errorMessage = getClockInTimeError({
      time,
      shiftStart: shift.start as string,
      shiftEnd: shift.end as string,
      isEarlyClockInEnabled,
      geofence: shiftGeofence,
      timezone: shift.facility?.tmz,
    });
    if (!errorMessage && shift.clockInOut?.end) {
      const errorTimekeepingMessage = await validateTimeKeeping({
        shiftId: shift._id!.toString(),
        editedClockIn: time,
        editedClockOut: shift.clockInOut.end,
      });
      return errorTimekeepingMessage ?? "";
    }
    return errorMessage;
  };

  const onClockInChange = async (time: string) => {
    const errorMessage = await validateNewClockIn(time);
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_CLOCK_IN__ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitClockIn(time);
      if (isDefined(submitClockOut)) {
        applyMandatoryBreak({ clockInOut: { start: time, end: submitClockOut } });
      }
    }
  };

  const onLunchOutChange = (time?: string) => {
    const errorMessage = getBreakStartTimeError({
      time,
      geofence: isSolveUnpaidBreaksEnabled ? undefined : shiftGeofence,
      timezone: shift.facility?.tmz,
    });
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_START_BREAK_ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitLunchOut(time);
      setIsBreakTimeTouched(true);
    }
  };

  const onLunchInChange = (time?: string) => {
    const errorMessage = getBreakEndTimeError({
      time,
      breakStartTime: submitLunchOut,
      geofence: isSolveUnpaidBreaksEnabled ? undefined : shiftGeofence,
      timezone: shift.facility?.tmz,
    });
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_END_BREAK_ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitLunchIn(time);
      setIsBreakTimeTouched(true);
    }
  };

  const validateNewClockOut = async (time: string) => {
    if (qrCodeFeedbackBeforeClockOutIsEnabled && isAfter(parseISO(time), new Date())) {
      return "Clock out time cannot be in the future";
    }
    const errorMessage = getClockOutTimeError({
      time,
      recordedClockOutTime: shift?.clockInOut?.end,
      shiftStart: shift.start as string,
      shiftEnd: shift.end as string,
      timezone: shift.facility?.tmz,
    });
    if (!errorMessage && shift.clockInOut?.start) {
      const errorTimekeepingMessage = await validateTimeKeeping({
        shiftId: shift._id!.toString(),
        editedClockIn: shift.clockInOut.start,
        editedClockOut: time,
      });
      return errorTimekeepingMessage ?? "";
    }
    return errorMessage;
  };

  const onClockOutChange = async (time: string) => {
    const errorMessage = await validateNewClockOut(time);
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_CLOCK_OUT_ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitClockOut(time);
      if (isDefined(submitClockIn)) {
        applyMandatoryBreak({ clockInOut: { start: submitClockIn, end: time } });
      }
    }
  };

  function applyMandatoryBreak(params: { clockInOut: { start: string; end: string } }) {
    const { clockInOut } = params;
    const doesNeedMandatoryBreak =
      isSolveUnpaidBreaksEnabled &&
      isMandatoryBreakEnabled &&
      doesShiftHaveWorkedBreak({
        clockInOut,
        lunchInOut: {
          start: submitLunchOut,
          end: submitLunchIn,
        },
      });

    if (doesNeedMandatoryBreak && !isBreakTimeTouched) {
      const defaultBreakTimes = calculateShiftMandatoryBreakTimes({
        clockInOut,
      });

      if (isDefined(defaultBreakTimes)) {
        setSubmitLunchOut(defaultBreakTimes.start.toISOString());
        setSubmitLunchIn(defaultBreakTimes.end.toISOString());
      }
    }
  }

  const onSave = async () => {
    setShowSpinner(true);
    const updatedSubmitClockIn = adjustTimeForMultiDayShifts(submitClockIn);
    const updatedSubmitClockOut = adjustTimeForMultiDayShifts(submitClockOut);
    const updatedSubmitLunchOut = adjustTimeForMultiDayShifts(submitLunchOut);
    const updatedSubmitLunchIn = adjustTimeForMultiDayShifts(submitLunchIn);
    setSubmitClockIn(updatedSubmitClockIn);
    setSubmitClockOut(updatedSubmitClockOut);
    setSubmitLunchOut(updatedSubmitLunchOut);
    setSubmitLunchIn(updatedSubmitLunchIn);
    const errorMessage = validateShiftTime({
      clockInTime: updatedSubmitClockIn,
      clockOutTime: updatedSubmitClockOut,
      breakStartTime: updatedSubmitLunchOut,
      breakEndTime: updatedSubmitLunchIn,
    });
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_TIMESHEET_SAVE_ERROR, errorMessage);
      showErrorToast(errorMessage);
      setShowSpinner(false);
      return;
    }

    // Removed geofence check as per requirements
    setClockInEditText(getClockInEditHelperText(updatedSubmitClockIn, originalClockInOut.start));
    setClockOutEditText(getClockOutEditHelperText(updatedSubmitClockOut, originalClockInOut.end));
    setLunchOutEditText(getLunchOutEditHelperText(updatedSubmitLunchOut, originalLunchInOut.start));
    setLunchInEditText(getLunchInEditHelperText(updatedSubmitLunchIn, originalLunchInOut.end));
    onShiftTimeSave(
      updatedSubmitClockIn,
      updatedSubmitClockOut,
      updatedSubmitLunchOut,
      updatedSubmitLunchIn
    );
    setShowSpinner(false);
  };

  const onCancel = () => {
    setSubmitClockIn(initialSubmissionClockInOut.start);
    setSubmitClockOut(initialSubmissionClockInOut.end);
    setSubmitLunchOut(initialSubmissionLunchInOut.start);
    setSubmitLunchIn(initialSubmissionLunchInOut.end);
    setShowSpinner(false);
    onShiftTimeEdit(false);
  };

  const onShiftTimeEditClick = async () => {
    onShiftTimeEdit(true);
    if (isRadarTimekeepingValidationsEnabled) {
      const { geofence } = await fetchShiftDetails({
        shiftId: shift._id as string,
      });
      setShiftGeofence(geofence);
    }
  };

  return (
    <div className="shift-time-summary">
      <div>
        <IonAlert
          header={alert?.header}
          message={alert?.message}
          isOpen={!!alert}
          // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
          onWillDismiss={shiftDetailsAlerts.dismissAlert}
          buttons={alert?.buttons}
          mode="ios"
        />
        <ShiftActionTime
          shiftActionTime={submitClockIn}
          title="Clock in"
          isEditable={isEditable}
          isClearable={false}
          isTitleError={false}
          helperText={clockInEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onClockInChange}
        />
        <ShiftActionTime
          shiftActionTime={submitLunchOut}
          title="Break start"
          isEditable={isEditable}
          isClearable={isSolveUnpaidBreaksEnabled || !hasGeofencedBreak}
          isTitleError={!isEditable && hasWorkedBreakTime}
          helperText={lunchOutEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onLunchOutChange}
        />
        <ShiftActionTime
          shiftActionTime={submitLunchIn}
          title="Break end"
          isEditable={isEditable}
          isClearable={isSolveUnpaidBreaksEnabled || !hasGeofencedBreak}
          isTitleError={!isEditable && hasWorkedBreakTime}
          helperText={lunchInEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onLunchInChange}
        />
        <ShiftActionTime
          shiftActionTime={submitClockOut}
          title="Clock out"
          isEditable={isEditable}
          isClearable={false}
          isTitleError={false}
          helperText={clockOutEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onClockOutChange}
        />
        {!isEditable && (
          <>
            <div className="divider-line"></div>
            <IonText className="total-work-time">
              {hours} hr, {minutes} min
            </IonText>
          </>
        )}
        {!isEditable && hasWorkedBreakTime && (
          <Text
            variant="body2"
            sx={{
              color: (theme) => theme.palette.error.main,
            }}
            align="right"
            marginTop={2}
          >
            {`This timesheet contains ${
              breakDurationInMinutes === 0 ? "a skipped" : "an incomplete"
            } break.`}
          </Text>
        )}
      </div>
      {isTimeSheetEditDisabled ? (
        <Text variant="body2">
          {shift.facility?.name ?? "The workplace"} does not allow you to edit your timesheet
          in-app. If you need to make adjustments to your clock in/out or break times, you will need
          to submit a paper timesheet.{" "}
          <a
            href="https://support.clipboardhealth.com/hc/en-us/articles/26726538551063-Editing-your-clock-in-and-clock-out-times-when-timesheet-editing-is-restricted"
            target="_blank"
            rel="noreferrer"
          >
            Tap here to learn how to submit a paper timesheet.
          </a>
        </Text>
      ) : (
        <div>
          {!isEditable && isNewTimeSheetEnabled && isTimeEditButtonVisible && (
            <IonButton className="edit-button" fill="outline" onClick={onShiftTimeEditClick}>
              Edit
            </IonButton>
          )}
          {isEditable && (
            <>
              <IonButton
                className="cancel-button shift-time-summary-button"
                disabled={showSpinner}
                onClick={() => onCancel()}
              >
                Cancel
              </IonButton>
              <IonButton
                disabled={showSpinner}
                className="save-button shift-time-summary-button"
                onClick={() => {
                  onSave();
                }}
              >
                {showSpinner && <IonSpinner className="spinner-style" />}Save
              </IonButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};
